import { render, staticRenderFns } from "./ChildrenList.vue?vue&type=template&id=7ac608a2&scoped=true&"
import script from "./ChildrenList.vue?vue&type=script&lang=js&"
export * from "./ChildrenList.vue?vue&type=script&lang=js&"
import style0 from "./ChildrenList.vue?vue&type=style&index=0&id=7ac608a2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac608a2",
  null
  
)

export default component.exports