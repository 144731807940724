<template>
  <div class="email-to-notify">
    <label class="email-to-notify__label">Notify booked loads to:</label>
    <Chips
      v-model.trim="emails"
      class="input-information-email"
      :placeholder="!emails.length ? 'Enter emails separated by comma or space' : ''"
    />
  </div>
</template>

<script>
import validateEmail from "@/utils/EmailValidation";
import Chips from "@/components/Chips.vue";

export default {
  name: "EmailToNotify",
  props: {
    emailNotify: {
      type: String,
      value: "",
    },
  },
  components: {
    Chips,
  },
  data() {
    return {
      emails: [],
      validEmail: true,
    };
  },
  watch: {
    emails: {
      handler() {
        const validEmails = this.emails.filter((email) => validateEmail(email));
        this.$emit("input", validEmails.join(","));
      },
      deep: true,
    },
    emailNotify: {
      handler() {
        const emailsList = this.emailNotify ? this.emailNotify.split(",") : [];
        this.emails = [...emailsList];
      },
      immediate: true,
    }
  },
  methods: {
    // Ejecutar la actualizacion de los emails
    getEmailsToNotify() {
      const validEmails = this.emails.filter((email) => validateEmail(email));
      return validEmails.join(",");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/RCComponents.scss";

.email-to-notify {
  margin: 5px 0px 20px 0px;
  text-align: left;
  &__label {
    font-size: 14px;
    color: #a0a1a5;
    margin: 0px;
  }
  &__input {
    width: 100%;
    &:first-child {
      margin-right: 5px;
    }
  }
  &__error {
    font-size: 12px;
    color: $color-error;
    margin-top: 5px;
  }
}

.input-information-email {
  @include font-small-standart-text;
  @include input-information;
  width: 100%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border: 1px solid $color-border-container;
  box-shadow: none;
  padding: 0px;
  border-radius: 10px;
  color: black;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}

::v-deep {
  .chips-container {
    &__input-container {
      width: 20%;
    }
  }
}
</style>
