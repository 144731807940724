<template>
  <div class="update-statements-modal">
    <CustomModal
      v-model="showModal"
      modalTitle="Update Account Statement"
      size="md"
      centered
      :showOverlay="uploadingStatement"
    >
      <template #modal-content>
        <DragAndDrop :allowedExtensions="allowedExtensions" v-model="uploadedFile" />
        <div class="buttons-container">
          <button class="buttons-container__save" @click="uploadStatement()">Accept</button>
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import CustomModal from "../../../../../components/CustomModal.vue";
import DragAndDrop from "../../../../../components/DragAndDropDecoupled.vue";

export default {
  name: "UpdateStatementsModal",
  components: {
    CustomModal,
    DragAndDrop,
  },
  props: {
    showStatementModal: Boolean,
    id: Number
  },
  watch: {
    showStatementModal: {
      handler(value) {
        this.showModal = value;
      }
    }
  },
  data() {
    return {
      uploadingStatement: false,
      uploadedFile: null,
      showModal: false,
      allowedExtensions: [
        "csv",
        "xla",
        "xls",
        "xlt",
        "xlr",
        "xlw",
        "xml",
        "xlam",
        "xlsb",
        "xlsx",
        "xlsm",
        "xltm",
      ],
    };
  },
  methods: {
    async uploadStatement() {
      if (!this.uploadedFile) {
        this.swal({
          title: "Error",
          text: "You must attach a valid file",
          icon: "warning",
        });
        return;
      }
      this.uploadingStatement = true;
      const formData = new FormData();
      formData.append("file", this.uploadedFile.raw);
      const payload = {
        formData,
        id: this.id,
      };
      await this.$store.dispatch("account/uploadStatement", payload);
      this.uploadingStatement = false;
      this.swal({
        title: "Success",
        text: "Statement uploaded successfully",
        icon: "success",
      });
      this.$emit("closeStatementModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.statements-button {
  position: absolute;
  right: 4px;
  top: 15px;
  height: 40px;
  border: none;
  padding: 0px 12px;
  background-color: $update-file-button;
  border-radius: 15px;
  font-size: 14px;
  color: white;
  &__text {
    display: inline-block;
    margin-left: 5px;
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @include font-button-text;
  &__save {
    @include status-button($color-save-button, $color-save-button, $color-primary-text-button);
    width: 45%;
    font-weight: 600 !important;
  }
}

::v-deep {
  .el-upload-dragger {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
