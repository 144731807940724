<template>
  <div class="account-details-form">
    <div class="details-group">
      <label class="details-group__label details-group__accounts">
        {{ $t("accountManagement.accountIds") }}
      </label>
      <AccountIdsSearcher
        v-model="selectedAccountId"
        :remountSearcher="remountSearcher"
        :enableEditing="true"
        @change="selectAccountId"
      />
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.companyName") }}</label>
      <input
        disabled
        type="text"
        v-model="accountInformation.company_name"
        :class="{ 'details-group__input': true, 'disabled-input': true }"
      />
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.companyAddress") }}</label>
      <input
        disabled
        type="text"
        :value="companyAddress"
        :class="{ 'details-group__input': true, 'disabled-input': true }"
      />
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.phoneNumber") }}</label>
      <input
        disabled
        type="text"
        :value="formatPhoneNumber(accountInformation.phone)"
        :class="{ 'details-group__input': true, 'disabled-input': true }"
      />
    </div>
    <div class="details-group">
      <label class="details-group__label">
        {{ $t("accountManagement.website") }}
      </label>
      <input type="text" v-model="accountInformationForm.website" class="details-group__input" />
    </div>
    <div class="details-group">
      <label class="details-group__label">
        {{ $t("accountManagement.countryCode") }}
      </label>
      <input
        disabled
        type="text"
        v-model="accountInformation.country_code"
        :class="{ 'details-group__input': true, 'disabled-input': true }"
      />
    </div>
  </div>
</template>

<script>
import AccountIdsSearcher from "@/components/NewAccountIdsSearcher.vue";
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";

export default {
  name: "AccountDetailsForm",
  props: {
    accountInformation: Object,
  },
  mixins: [FormatPhoneNumber],
  components: {
    AccountIdsSearcher,
  },
  data() {
    return {
      selectedAccountId: null,
      remountSearcher: 0,
      accountInformationForm: null,
    };
  },
  computed: {
    companyAddress() {
      /* eslint-disable */
      const address = `${this.accountInformation.company_address} - ${this.accountInformation.city}, ${this.accountInformation.state_code}, ${this.accountInformation.zip_code}`;
      return address;
    },
  },
  watch: {
    accountInformationForm: {
      handler() {
        this.$emit("input", this.accountInformationForm);
      },
      immediate: true,
      deep: true,
    },
    accountInformation: {
      immediate: true,
      handler(value) {
        this.accountInformationForm = {
          account_id: this.accountInformation.account_id,
          account_name: this.accountInformation.account_name,
          website: this.accountInformation.website,
        };
        const accountId = {
          id: this.accountInformation.account_id,
          name: this.accountInformation.account_name,
        };
        this.selectedAccountId = accountId;
      },
    },
  },
  methods: {
    selectAccountId(data) {
      this.accountInformationForm.account_id = data.id;
      this.accountInformationForm.account_name = data.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.details-group {
  text-align: left;
  color: $color-primary-company;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &__label {
    @include font-standart-text;
    font-weight: bold;
    margin-bottom: 0px;
  }
  &__input {
    @include input-information;
    padding: 18px;
    height: 40px;
    width: 100%;
    margin-top: 5px;
  }
}

.disabled-input {
  background-color: #f3f3f3;
}
</style>
