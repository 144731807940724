<template>
  <div class="children-accounts" v-loading="loading">
    <EmailToNotify ref="emailToNotify" v-model="emailNotify" :emailNotify="emailNotify" />
    <AccountsList
      v-if="childrenAccounts"
      ref="childrenAccounts"
      :childrenAccounts="childrenAccounts"
      :accountInformation="accountInformation"
    />
  </div>
</template>

<script>
import EmailToNotify from "./EmailToNotify.vue";
import AccountsList from "./ChildrenList.vue";

export default {
  name: "ChildrenAccounts",
  components: {
    EmailToNotify,
    AccountsList,
  },
  props: {
    accountInformation: Object,
    childrenAccounts: Array,
  },
  data() {
    return {
      loading: false,
      emailNotify: null,
    };
  },
  watch: {
    accountInformation: {
      handler() {
        this.emailNotify = this.accountInformation.email_notify;
      },
    },
  },
  methods: {
    validateMissingInformation() {
      const missingInformation = this.$refs.childrenAccounts.validateMissingInformation();
      return !missingInformation;
    },
    async childrenActionHandler() {
      const childrenAccounts = this.$refs.childrenAccounts.getChildrenAccountsList();
      const promises = [];
      childrenAccounts.forEach((account) => {
        const foundAccount = this.childrenAccounts.find(
          (item) => item.children_id === account.children_id
        );
        if (!foundAccount) {
          promises.push(this.createChildrenAccount(account));
        } else if (foundAccount.notify !== account.notify) {
          promises.push(this.updateChildrenAccount(account));
        }
      });
      const promisesResponse = await Promise.all(promises).then((response) => response);
      promisesResponse.forEach((item) => {
        const payload = {
          account_id: this.accountInformation.id,
          body: item.data,
        };
        if (item.operation === "create") {
          this.$store.commit("accountChildren/addChildAccount", payload);
        } else {
          this.$store.commit("accountChildren/updateChildAccount", { ...payload, id: item.id });
        }
      });
      this.getChildrenListToUpdate();
      return childrenAccounts;
    },
    async createChildrenAccount(childAccount) {
      const payload = {
        account_id: this.accountInformation.id,
        children_id: childAccount.children_id,
        children_name: childAccount.name,
        notify: childAccount.notify,
      };
      const resposne = await this.$store.dispatch("accountChildren/createChildAccount", payload);
      return resposne;
    },
    async updateChildrenAccount(childAccount) {
      const payload = {
        id: childAccount.children_id,
        body: {
          notify: childAccount.notify,
        },
      };
      const response = await this.$store.dispatch("accountChildren/updateChildAccount", payload);
      return response;
    },
    getChildrenListToUpdate() {
      const childrenAccounts = this.$refs.childrenAccounts.getChildrenAccountsList();
      this.$emit("getChildrenListToUpdate", childrenAccounts);
    },
  },
};
</script>

<style lang="scss" scoped></style>
