<template>
  <div class="abilities-checkboxes">
    <div class="abilities-checkboxes__checkboxes">
      <Checkbox
        id="branded"
        v-model="abilitiesForm.is_branded"
        :binary="true"
        @change="formHandler"
      />
      <label for="branded">This is a branded account</label>
    </div>
    <div class="abilities-checkboxes__checkboxes">
      <Checkbox
        id="can-dispatch"
        v-model="abilitiesForm.can_dispatch"
        :binary="true"
        @change="formHandler"
      />
      <label for="can-dispatch">This account can dispatch loads</label>
    </div>
    <div class="abilities-checkboxes__checkboxes">
      <Checkbox
        id="show-cost"
        v-model="abilitiesForm.show_cost"
        :binary="true"
        @change="formHandler"
      />
      <label for="show-cost">This account can see the shipping cost</label>
    </div>
    <div class="abilities-checkboxes__checkboxes">
      <el-switch v-model="abilitiesForm.is_active" @change="formHandler"> </el-switch>
      <label>
        Status:
        {{ abilitiesForm.is_active ? "active" : "inactive" }}
      </label>
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";

export default {
  name: "AbilitiesCheckboxes",
  props: {
    accountInformation: Object,
  },
  components: {
    Checkbox,
  },
  data() {
    return {
      abilitiesForm: {},
    };
  },
  watch: {
    accountInformation: {
      immediate: true,
      handler(value) {
        if (typeof value === "object" && Object.keys(value).length > 0) {
          this.setAbilitiesForm();
          this.formHandler();
        }
      },
    },
  },
  methods: {
    setAbilitiesForm() {
      this.abilitiesForm = {
        is_branded: this.accountInformation.is_branded,
        can_dispatch: this.accountInformation.can_dispatch,
        show_cost: this.accountInformation.show_cost,
        is_active: this.accountInformation.is_active,
      };
    },
    formHandler() {
      this.$emit("input", this.abilitiesForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.abilities-checkboxes {
  @include font-button-text;
  color: $color-primary-company;
  &__checkboxes {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    label {
      margin-left: 10px;
    }
  }
}

::v-deep {
  .active {
    span {
      border-color: $color-border-container !important;
      background-color: $color-border-container !important;
    }
  }

  .el-switch.is-checked .el-switch__core {
    border-color: $color-border-container !important;
    background-color: $color-border-container !important;
  }
  .p-checkbox .p-checkbox-box {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid $color-border-container;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    background: $color-border-container;
    background-color: $color-border-container;
    border-color: $color-border-container;
    &:hover {
      background: $color-border-container;
      background-color: $color-border-container;
      border-color: $color-border-container;
    }
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: $color-border-container;
    box-shadow: none;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $color-border-container;
  }
}
</style>
