<template>
  <ul class="children-accounts-list" v-loading="deletingAccount">
    <div class="children-accounts-list__header" v-if="localValue.length > 0">
      <strong>Name</strong>
      <strong>ID</strong>
      <strong>Notify</strong>
    </div>
    <li
      v-for="(account, index) in localValue"
      :key="index"
      :id="index"
      class="children-accounts-list-form"
    >
      <CustomersSearcher
        @change="selectCustomer($event, index)"
        label="Name"
        class="children-accounts-list-form__input"
        :value="account"
        :class="{ 'missing-field': validatingInputs && account.name === '' }"
        :floatLabel="false"
        :enableEditing="account.name ? false : true"
        :forceSelection="true"
      />
      <RCInput
        class="children-accounts-list-form__input"
        v-model="account.children_id"
        label="Account ID"
        :disabled="true"
      />
      <button class="children-accounts-list-form__delete" @click="deleteItem(index)">X</button>
      <b-form-checkbox class="document-item__checkbox" v-model="account.notify" />
    </li>
    <div class="children-accounts-list-add">
      <button class="children-accounts-list-add__button" @click="addAccount()">
        <b-icon icon="plus" font-scale="2.5"></b-icon>
        ADD ACCOUNT
      </button>
    </div>
  </ul>
</template>

<script>
import RCInput from "@/components/RCComponents/CustomRCInput.vue";
import CustomersSearcher from "@/components/AccountIdsSearcher.vue";

export default {
  name: "AccountsList",
  components: {
    CustomersSearcher,
    RCInput,
  },
  props: {
    childrenAccounts: {
      type: Array,
      default: () => [],
    },
    accountInformation: Object,
  },
  data() {
    return {
      localValue: [],
      emptyInputsIndexes: [],
      validatingInputs: false,
      deletingAccount: false,
    };
  },
  created() {
    const childrenAccounts = this.childrenAccounts.map((item) => ({
      name: item.children_name,
      ...item,
    }));
    this.localValue = this.$deepCopy(childrenAccounts);
  },
  methods: {
    addAccount() {
      this.localValue.push({ name: "", children_id: "", notify: false });
    },
    async deleteItem(index) {
      this.deletingAccount = true;
      const childrenId = this.localValue[index]?.id;
      if (childrenId) {
        await this.$store.dispatch("accountChildren/deleteChildAccount", childrenId);
        this.$store.commit("accountChildren/deleteChildAccount", {
          account_id: this.accountInformation.id,
          id: childrenId,
        });
      }
      this.localValue.splice(index, 1);
      this.deletingAccount = false;
    },
    selectCustomer(customer, index) {
      this.localValue[index].name = customer?.name ? customer.name : "";
      this.localValue[index].children_id = customer?.id ? customer.id : "";
    },
    validateMissingInformation() {
      this.validatingInputs = true;
      const missingInformation = this.localValue.some(
        (account) => account.name === "" || account.children_id === ""
      );
      return missingInformation;
    },
    getChildrenAccountsList() {
      return this.localValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/RCComponents.scss";
@import "@/assets/scss/components/Checkboxes.scss";

.children-accounts-list {
  margin: 15px 0px 5px 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  &__header {
    strong {
      width: 100%;
    }
    display: grid;
    text-align: left;
    grid-template-columns: 1fr 1fr 0fr;
    margin-left: 5px;
  }
}

.children-accounts-list-add {
  &__button {
    display: flex;
    align-items: center;
    color: $color-primary-company;
    border: none;
    padding: 0px;
    background: none;
    font-size: 14px;
  }
}

.children-accounts-list-form {
  margin: 5px 0px;
  display: flex;
  align-items: center;
  &__input {
    width: 100%;
    &:first-child {
      margin-right: 5px;
    }
  }
  &__delete {
    background: none;
    border: none;
    color: $color-primary-company;
    font-size: 14px;
  }
}

::v-deep {
  .custom-checkbox .custom-control-label:before {
    padding: 5px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    padding: 5px;
  }

  .custom-control-label::after {
    width: 0.3rem;
    height: 0.3rem;
    top: 0.2rem;
    left: -1.55rem;
  }

  .rc-input-container,
  .rc-searcher-container {
    margin-top: 0px;
    border: 2px solid rgb(221, 221, 221);
    border-radius: 15px;
    box-shadow: 2px 2px 5px -2px #c4c4c4;
    &:focus-within,
    &:hover {
      box-shadow: 2px 2px 4px var(--color-sidebar-shadow);
    }
  }

  .rc-searcher-container {
    margin-bottom: 0px;
  }

  .rc-form-group {
    font-size: 14px;
  }

  .rc-input-field,
  .rc-searcher-field {
    font-size: 14px;
  }

  .rc-searcher-container .rc-form-group__label,
  .rc-searcher__spinner {
    top: 0px;
  }
}
</style>
