<template>
  <div class="quote-modes">
    <div class="modes-switches">
      <div
        class="switch-container"
        v-for="mode in modesData"
        :class="oneModeIsEnabled ? 'not-allowed-cursor' : ''"
        :key="mode.id"
      >
        <el-switch
          v-model="mode.enabled"
          :disabled="mode.enabled && oneModeIsEnabled"
          @change="switchHandler"
        >
        </el-switch>
        <label>
          {{ mode.title }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import modes from "../../../../../QuoteBook/modes";

export default {
  name: "ModesSection",
  props: {
    accountInformation: Object,
  },
  data() {
    return {
      modesData: {},
      enabledModesQuantity: 0,
      oneModeIsEnabled: false,
    };
  },
  watch: {
    modesData: {
      deep: true,
      immediate: true,
      handler() {
        this.oneModeIsEnabled =
          Object.values(this.modesData).filter((mode) => mode.enabled).length === 1;
      },
    },
    accountInformation: {
      immediate: true,
      handler(value) {
        if (typeof value === "object" && Object.keys(value).length > 0) {
          this.modesData = this.$deepCopy(modes);
          delete this.modesData["all-options"];
          this.modesData.ltl.enabled = this.accountInformation.can_dispatch_ltl;
          this.modesData.ftl.enabled = this.accountInformation.can_dispatch_ftl;
          this.modesData.expedited.enabled = this.accountInformation.can_dispatch_exp;
          this.modesData.insurance.enabled = this.accountInformation.can_dispatch_insurance;
          this.modesData.flatbed.enabled = this.accountInformation.can_dispatch_flatbed;
          this.modesData.reefer.enabled = this.accountInformation.can_dispatch_reefer;
          this.switchHandler();
        }
      },
    },
  },
  methods: {
    switchHandler() {
      const modesData = {
        can_dispatch_ltl: this.modesData.ltl.enabled,
        can_dispatch_ftl: this.modesData.ftl.enabled,
        can_dispatch_exp: this.modesData.expedited.enabled,
        can_dispatch_insurance: this.modesData.insurance.enabled,
        can_dispatch_flatbed: this.modesData.flatbed.enabled,
        can_dispatch_reefer: this.modesData.reefer.enabled,
      };
      this.$emit("input", { payload: modesData, completeData: this.modesData });
    },
  },
};
</script>

<style lang="scss" scoped>
.modes-switches {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  text-align: start;
  margin: 15px auto;
}

.switch-container {
  display: flex;
  justify-content: flex-start;
  label {
    margin-left: 7px;
    font-size: 12px;
  }
}

.not-allowed-cursor ::v-deep .el-switch.is-checked .el-switch__core {
  cursor: not-allowed;
}

::v-deep {
  .active {
    span {
      border-color: $color-border-container !important;
      background-color: $color-border-container !important;
    }
  }
  .el-switch.is-checked .el-switch__core {
    border-color: $color-border-container !important;
    background-color: $color-border-container !important;
  }
}
</style>
